import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import useScheduleSearchBarQueryStates from "@sellernote/shipda-kr/src/hooks/forwarding/useScheduleSearchBarQueryStates";

type Search = Pick<
  ReturnType<typeof useScheduleSearchBarQueryStates>[0],
  | "freightType"
  | "polId"
  | "polPortGroupId"
  | "podId"
  | "podPortGroupId"
  | "etd"
  | "eta"
>;

type SearchAction = (newSearch: Partial<Search>) => void;

type SearchValue = [Search, SearchAction];

const SearchContext = createContext<SearchValue | undefined>(undefined);

const useGetInitialSearch = () => {
  const [
    { freightType, polId, polPortGroupId, podId, podPortGroupId, etd, eta },
  ] = useScheduleSearchBarQueryStates();

  return {
    freightType,
    polId,
    polPortGroupId,
    podId,
    podPortGroupId,
    etd,
    eta,
  };
};

const useSearch = () => {
  const value = useContext(SearchContext);

  if (value === undefined) {
    throw new Error("useSearch should be used within SearchProvider");
  }

  return value;
};

function SearchProvider({ children }: { children: ReactNode }) {
  const [search, setSearch] = useState<Search>(useGetInitialSearch());

  const searchAction: SearchAction = useCallback((newSearch) => {
    setSearch((prevSearch) => ({ ...prevSearch, ...newSearch }));
  }, []);

  const searchValue: SearchValue = useMemo(
    () => [search, searchAction],
    [search, searchAction]
  );

  return (
    <SearchContext.Provider value={searchValue}>
      {children}
    </SearchContext.Provider>
  );
}

export { useSearch, SearchProvider };
