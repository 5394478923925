import {
  RefObject,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

import useClickOutsideOfElement from "@sellernote/_shared/src/hooks/common/useClickOutsideOfElement";
import { ShipmentType } from "@sellernote/_shared/src/types/forwarding/common";
import { useDebounce } from "@sellernote/_shared/src/utils/common/hook";
import { removeLeadingBlank } from "@sellernote/_shared/src/utils/common/string";
import InputSearch, {
  InputSearchLabelInfo,
  InputSearchProps,
} from "@sellernote/_sds-v2/src/components/form/InputSearch";

import RecommendedList from "./RecommendationList";
import SearchResultOptions from "./SearchResultOptions";
import Styled from "./index.styles";

type InputSearchForScheduleOption<T> = {
  label: string;
  value: T;
  iconInfo?: InputSearchProps["leftIconInfo"];
};

type InputSearchForScheduleHandlerList = {
  focusInput: () => void;
};

type InputSearchWithForScheduleProps<T> = {
  searchSourceList: InputSearchForScheduleOption<T>[];
  selectedOptionValue: T;
  onSelect: (value: T) => void;
  onReset: () => void;
  /** labelInfo.position이 left 인 경우 min-width를 필수로 넣어야합니다. */
  labelInfo: InputSearchLabelInfo;
  placeholder: React.ReactNode;
  width?: number;
  recommendationList: RecommendationListType<T>;
  inputSearchForScheduleHandlerList: RefObject<InputSearchForScheduleHandlerList>;
  leftIconInfo?: InputSearchProps["leftIconInfo"];
  className?: string;
};

type OptionListState = "closed" | "recommendationList" | "searchList";

type RecommendationListType<T> = {
  shipmentType: ShipmentType;
  list: InputSearchForScheduleOption<T>[];
}[];

export type { RecommendationListType, InputSearchForScheduleOption };

/**
 * 추천목록으로 인해 InputSearchWithOptionsV2 코드를 가져와서 해당 페이지에 맞게 수정한 컴포넌트
 * 추후 InputSearchWithOptionPanel로 변경 예정 (디자인 요청)
 */
export default function InputSearchForSchedule<T>({
  searchSourceList = [],
  selectedOptionValue,
  onSelect,
  onReset,
  labelInfo,
  placeholder,
  leftIconInfo,
  width,
  recommendationList,
  inputSearchForScheduleHandlerList,
  className,
}: InputSearchWithForScheduleProps<T>) {
  const [inputValue, setInputValue] = useState("");
  const [optionListState, setOptionListState] =
    useState<OptionListState>("closed");

  const inputRef = useRef<HTMLInputElement>(null);

  const handleRecommendedListOpen = () => {
    setOptionListState("recommendationList");
  };

  const handleSearchListOpen = () => {
    setOptionListState("searchList");
  };

  const handleOptionListClose = () => {
    setOptionListState("closed");
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 선택한 옵션이 있을 경우에는 리셋버튼으로만 수정 가능.
    if (selectedOptionValue) return;

    const value = removeLeadingBlank(e.target.value);

    setInputValue(value);

    if (value) {
      handleSearchListOpen();
    } else {
      handleRecommendedListOpen();
    }
  };

  const debouncedSearchTerm = useDebounce(inputValue, 500);

  const { targetElementRef: selectOptionElementRef } = useClickOutsideOfElement(
    {
      onClickOutside: () => {
        handleOptionListClose();
      },
    }
  );

  const handleReset = () => {
    onReset();

    setInputValue("");

    handleRecommendedListOpen();
  };

  const handleSelect = (value: T) => {
    onSelect(value);

    handleOptionListClose();
  };

  const handleFocus = () => {
    if (!inputValue && !selectedOptionValue) {
      inputRef?.current?.focus();
      handleRecommendedListOpen();
    }
  };

  useImperativeHandle(inputSearchForScheduleHandlerList, () => ({
    focusInput: handleFocus,
  }));

  const inputValueOfSelectedOptionValue = useMemo(
    () =>
      searchSourceList.find((option) => option.value === selectedOptionValue)
        ?.label,
    [searchSourceList, selectedOptionValue]
  );

  const inputValueToDisplay = selectedOptionValue
    ? inputValueOfSelectedOptionValue
    : inputValue;

  return (
    <Styled.container
      width={width}
      ref={selectOptionElementRef}
      className={`${className ? className : ""} input-search-for-schedule`}
    >
      <InputSearch
        inputRef={inputRef}
        labelInfo={labelInfo}
        inputValue={inputValueToDisplay || ""}
        leftIconInfo={leftIconInfo}
        onInputValueChange={handleChange}
        onReset={handleReset}
        isFocused={optionListState !== "closed"}
        onFocus={handleFocus}
        placeholder={placeholder}
      />

      {optionListState === "recommendationList" && !selectedOptionValue && (
        <RecommendedList
          onSelect={handleSelect}
          recommendationList={recommendationList}
        />
      )}

      {optionListState === "searchList" && !selectedOptionValue && (
        <SearchResultOptions
          width="100%"
          searchSourceList={searchSourceList}
          labelInfo={labelInfo}
          searchTerm={debouncedSearchTerm}
          onSelect={handleSelect}
        />
      )}
    </Styled.container>
  );
}
