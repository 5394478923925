import { useCallback, useMemo } from "react";
import { Trans } from "react-i18next";
import purify from "dompurify";

import {
  getSearchTermRegex,
  removeSpacesAndConvertToLowerCase,
} from "@sellernote/_shared/src/utils/common/string";
import FormStyled from "@sellernote/_sds-v2/src/components/form/index.styles";
import { InputSearchLabelInfo } from "@sellernote/_sds-v2/src/components/form/InputSearch";
import { InputSearchOption } from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";

import { InputSearchForScheduleOption } from ".";
import Styled from "./index.styles";
interface SearchResultOptionsProps<T> {
  width: string | number;
  labelInfo?: InputSearchLabelInfo;
  searchTerm: string;
  searchSourceList: InputSearchForScheduleOption<T>[];
  onSelect?: (value: T) => void;
}

export default function SearchResultOptions<T>({
  width,
  labelInfo,
  searchTerm,
  searchSourceList,
  onSelect,
}: SearchResultOptionsProps<T>) {
  const getSearchResult = () => {
    if (!searchTerm) {
      return [];
    }

    return searchSourceList.filter((searchOptionItem) => {
      // 공백을 제거한 라벨값
      const labelWithoutSpaces = removeSpacesAndConvertToLowerCase(
        searchOptionItem.label
      );

      // 공백을 제거한 검색어
      const searchTermWithoutSpaces =
        removeSpacesAndConvertToLowerCase(searchTerm);

      return labelWithoutSpaces.includes(searchTermWithoutSpaces);
    });
  };

  const searchResult = useMemo(getSearchResult, [searchSourceList, searchTerm]);

  const createHighlightingStyle = useCallback(
    (
      searchTerm: string,
      searchOptionList: InputSearchForScheduleOption<T>[]
    ) => {
      return searchOptionList.map((option) => {
        // 공백을 제거한 검색어
        const searchTermWithoutSpaces =
          removeSpacesAndConvertToLowerCase(searchTerm);

        // 공백을 제거한 검색어를 기준으로 라벨값을 포함하는 정규식 생성
        const searchTermRegex = getSearchTermRegex(searchTermWithoutSpaces);

        return {
          ...option,
          label: option.label.replace(
            searchTermRegex,
            (match) => `<strong>${match}</strong>`
          ),
        };
      });
    },
    []
  );

  const highlightedSearchResults = useMemo(() => {
    return createHighlightingStyle(searchTerm, searchResult);
  }, [createHighlightingStyle, searchTerm, searchResult]);

  const handleSearchResultSelect = ({ value }: InputSearchOption<T>) => {
    onSelect?.(value);
  };

  return (
    <>
      {highlightedSearchResults.length ? (
        <FormStyled.optionList
          className="custom-option-list"
          labelPosition={labelInfo?.position || "top"}
          optionListPosition="bottom"
          width={width}
        >
          {highlightedSearchResults.map(({ label, value, iconInfo }, i) => (
            <Styled.optionList
              key={`search-option-item-${i}`}
              onClick={() => handleSearchResultSelect({ label, value })}
            >
              {iconInfo?.Icon && <iconInfo.Icon />}

              <span
                className="search-result-label"
                dangerouslySetInnerHTML={{
                  __html: purify.sanitize(label),
                }}
              />
            </Styled.optionList>
          ))}
        </FormStyled.optionList>
      ) : (
        <FormStyled.optionList
          labelPosition={labelInfo?.position || "top"}
          optionListPosition="bottom"
          width={width}
        >
          <li>
            <span className="no-search-results">
              <Trans i18nKey="sds-v2:InputSearchWithOptions_검색결과없음" />
            </span>
          </li>
        </FormStyled.optionList>
      )}
    </>
  );
}
