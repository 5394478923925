import styled from "styled-components";

import { showVerticalScrollBarAlways } from "@sellernote/_shared/src/styles/functions";
import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const container = styled.div<{ width?: number }>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : "100%")};

  .custom-option-list {
    max-height: 320px;
    ${showVerticalScrollBarAlways()}
  }
`;

const shipmentType = styled.div`
  padding: 8px 4px 0 12px;
  ${setFontStyle("SubHead3")};
  color: ${COLOR.secondary_400};
`;

const optionList = styled.li`
  display: flex;
  gap: 8px;

  & > svg {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
  }
`;

export default {
  container,
  shipmentType,
  optionList,
};
